var owl = $('.owl-carousel');
owl.owlCarousel({
    items: 1,
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 7500,
    autoplayHoverPause: true
});

$(".card-submit").click(function () {
    var form = $(this).closest(".card-form");
    var isCard = $('.item-product input[name=card]').is(':checked');
    var isTerm = $('.terms').is(':checked');

    if (isCard && isTerm) {
        form.submit();
    } else if (!isCard) {
        swal('Please', 'Choose a card to continue.', 'warning');
    } else if (!isTerm) {
        swal('Please', 'Accept terms and condition to continue.', 'warning');
    }
});

$("#card_modal").on("click", ".clickable_card[name=card]", function () {
    $(".item-product").removeClass("selected");
    $(this).prop("checked", true).parent().addClass("selected");
});

function call_ajax(data) {
    $(".list-product").html("");
    $(".modal-title").html(data.modal.title);
    if(data.back_content) {
        $(".back-content").show();
    }else {
        $(".back-content").hide();
    }
    if(data.modal.footer) {
        $(".modal-footer").show();
    }else {
        $(".modal-footer").hide();
    }
    $(".modal-message").html(data.modal.message);
    $.ajax({
        type: 'get',
        url: data.action
    }).done(function (cards) {
        var html = "";
        $.each(cards, function (key, card) {
            html += "" +
                    "<li class='item-product' data-id='" + card.id + "' data-name='" + card.name + "'>" +
                    "<input class='clickable_card' type='radio' id='" + data.type + "_" + card.id + "' name='" + data.type + "' value='" + card.id + "'>" +
                    "<label href='javascript:;' for='" + data.type + "_" + card.id + "'>" +
                    "<img src='" + data.asset + "/" + card.image + "' alt='' style='pointer-events: none;'/>" +
                    "</label>" +
                    "<p>" + card.name + "</p>" +
                    "</li>" +
                    "";
        });
        $(".list-product").html(html);
    }).fail(function () {
        alert('data loading failed');
    });
}

$("#card_modal").on("click", ".clickable_card[name=subcategory]", function () {
    var id = $(this).val();
    var title = $(this).closest(".item-product").data('name');
    var data = {
        'action' : "card/subcategories/" + id + "/cards/ajax",
        'type': "card",
        'asset': $("input[name=asset]").data("card"),
        'modal' : {
            'title' : title,
            'message' : "",
            'footer' : true,
        },
        'back_content' : true,
    };
    call_ajax(data);
});

$("#card_modal").on("click", ".clickable_card[name=category]", function () {
    var id = $(this).val();
    var title = $(this).closest(".item-product").data('name');
    var data = {
        'action' : "card/categories/" + id + "/cards/ajax",
        'type': "card",
        'asset': $("input[name=asset]").data("card"),
        'modal' : {
            'title' : title,
            'message' : "",
            'footer' : true,
        },
        'back_content' : true,
    };
    call_ajax(data);
});


function subcategory_list() {
    var self = window.category_item;
    var id = self.children("a").data("category");
    var title = self.children("a").data("name");

    var data = {
        'action' : "card/categories/" + id + "/subcategories/ajax",
        'type': "subcategory",
        'asset': $("input[name=asset]").data("subcategory"),
        'modal' : {
            'title' : title,
            'message' : "",
            'footer' : false,
        },
        'back_content' : false,
    };
    call_ajax(data);
}

function category_list() {
    var data = {
        'action' : "card/categories/new/ajax",
        'type': "category",
        'asset': $("input[name=asset]").data("category"),
        'modal' : {
            'title' : "Ebl Card Categories",
            'message' : '<p class="text-muted bold">Please ensure you have opened an account with EBL. If not, please visit your nearest branch to apply for an EBL account and fill out the EBL PIC debit card form first</p>',
            'footer' : false,
        },
        'back_content' : false,
    };
    call_ajax(data);
}

$(document).on("click", ".new-card-holder", function () {
    window.new_card_holder = true;
    category_list();
});
$(".list-category").on("click", ".item-category", function () {
    window.category_item = $(this);
    window.new_card_holder = false;
    subcategory_list();
});
$(document).on("click", ".back-content", function () {
    if(window.new_card_holder === true) {
        category_list();
    }else {
        subcategory_list();
    }
});
